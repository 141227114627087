<template>
  <b-button
    variant="primary"
    size="sm"
    @click="onClick"
    class="minus-button"
  >
    -
  </b-button>
</template>

<script>
import { getClickEventIncrement } from '@/helpers';

export default {
  name: 'MinusButton',
  props: {
    value: {},
    min: {
      type: [Number, String],
    },
  },
  methods: {
    onClick(event) {
      const min = this.min === undefined ? Number.NEGATIVE_INFINITY : parseInt(this.min, 10);
      const number = Math.max(min, parseInt(this.value, 10) - getClickEventIncrement(event));
      this.$emit('input', number);
    },
  },
};
</script>

<style lang="scss" scoped>
  .minus-button {
    display: block;
    width: 32px;
  }
</style>
